import {AttemptedDelivery, IAttemptedDelivery} from 'app/blocks/model/attempted-delivery-model';
import {IPackageReturns, PackageReturns} from 'app/blocks/model/package-returns.model';
import {IZoneMap, ZoneMap} from 'app/blocks/model/zone-map.model';
import {OrganisationExtraType, IOrganisationExtraType} from 'app/blocks/model/organisation-extra-type.model';
import {IMetric, Metric} from 'app/blocks/model/metric.model';
import {ExtraType} from 'app/blocks/model/extra-type.model';
import {IExtraType} from 'app/blocks/model/extra-type.model';
import {BusinessType} from 'app/blocks/model/business-type.model';
import {IBusinessType} from 'app/blocks/model/business-type.model';
import {INominalCode, NominalCode} from 'app/blocks/model/nominal-code.model';
import {IResourceEventType, ResourceEventType} from 'app/blocks/model/resource-event-type.model';
import * as _ from 'lodash';

import {IResourceEvent, ResourceEvent} from 'app/blocks/model/resource-event.model';
import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {DEBUG_PAGE_PARAMS} from 'app/app.constants';
import {HaulierOrder, IHaulierOrder} from 'app/blocks/model/haulier-order.model';
import {IOrganisation, Organisation} from 'app/blocks/model/organisation.model';
import {ITrailerType, TrailerType} from 'app/blocks/model/trailer-type.model';
import {ListViewParams} from 'app/blocks/util/list-view-params';
import {AlertService} from 'app/common/alert/alert.service';
import {Resource} from 'app/constants/resource';
import {saveAs} from 'file-saver';
import produce from 'immer';

import {ICountry} from 'app/blocks/model/country.model';
import {ICurrency} from 'app/blocks/model/currency.model';
import {HandheldProfile, IHandheldProfile} from 'app/blocks/model/handheld-profile.model';
import {ITrailer, Trailer} from 'app/blocks/model/trailer.model';
import {IVehicle, Vehicle} from 'app/blocks/model/vehicle.model';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {SettingMetaDataService} from 'app/blocks/service/api/setting-meta-data.service';
import {EntityActionHandler} from 'app/blocks/store/action-handler/entity-action-handler';
import {
    AbstractGetNamedEntityList,
    CancelInlineEntityUpdate,
    ChangeEntityListViewParams,
    CreateEntity,
    CreateInlineEntity,
    DuplicateEntity,
    ExportEntities,
    GetEntityList,
    GetNamedEntityList,
    GetNewEntity,
    GetNewEntityWithSettings,
    ICancelInlineEntityUpdate,
    ICreateInlineEntity,
    IDuplicateEntity,
    IEntityAction,
    IExportEntities,
    IGetNewEntityWithSettings,
    IPopulateEntity,
    IPopulateEntityList,
    IReplaceEntityListItem,
    IInsertEntityListItem,
    ISelectAllCurrentPageEntities,
    ISelectEntity,
    ISelectEntityTabIndex,
    IStartInlineEntityAdd,
    IStartInlineEntityUpdate,
    IUnselectAllCurrentPageEntities,
    IUnselectEntity,
    IUpdateInlineEntity,
    PopulateEntity,
    PopulateEntityList,
    ReplaceEntityListItem,
    InsertEntityListItem,
    SelectAllCurrentPageEntities,
    SelectEntity,
    SelectEntityTabIndex,
    StartInlineEntityAdd,
    StartInlineEntityUpdate,
    UnselectAllCurrentPageEntities,
    UnselectEntity,
    UpdateEntity,
    UpdateInlineEntity,
    DeleteInlineEntity,
    IDeleteInlineEntity,
    ForceGetEntityList,
    IForceGetEntityList,
    IActivateResource,
    ActivateResource,
    GetOutstandingCallIns,
    IGetOutstandingCallIns,
    ChangeEntityFilterParams
} from 'app/blocks/store/actions/entity-actions';
import {FuelSurchargeGroup, IFuelSurchargeGroup} from 'app/blocks/model/fuel-surcharge-group.model';
import {FuelSurcharge, IFuelSurcharge} from 'app/blocks/model/fuel-surcharge.model';
import {
    DeleteEntity,
    GetEntity,
    IChangeEntityListViewParams,
    ICreateEntity,
    IDeleteEntity,
    IGetEntity,
    IGetEntityList,
    IGetNewEntity,
    IUpdateEntity
} from 'app/blocks/store/actions/entity-actions';

import {Extra, IExtra} from 'app/blocks/model/extra.model';
import {IScale, Scale} from 'app/blocks/model/scale.model';

import {defaultEntityState, EntityStateModel} from 'app/blocks/store/state-model/entity-state-model';
import {defaultPartialEntityState, PartialEntityStateModel} from 'app/blocks/store/state-model/partial-entity-state-model';
import {IVehicleClass, VehicleClass} from 'app/blocks/model/vehicle-class.model';
import {IExchangeRate, ExchangeRate} from 'app/blocks/model/exchange-rate.model';
import {IContactInfo, ContactInfo} from 'app/blocks/model/contact-info.model';
import {InlineEntityStateModel, defaultInlineEntityState} from 'app/blocks/store/state-model/inline-entity-state-model';
import {IServiceLevel, ServiceLevel} from 'app/blocks/model/service-level.model';
import {CustomGoodsType, ICustomGoodsType} from 'app/blocks/model/custom-goods-type.model';
import {ILocationPoint, LocationPoint} from 'app/blocks/model/location-point.model';
import {IExpenseType, ExpenseType} from 'app/blocks/model/expense-type.model';
import {EventType} from 'app/blocks/model/event-type.model';
import {IDriver, Driver} from 'app/blocks/model/driver.model';
import {VehicleInspectionReport, IVehicleInspectionReport} from 'app/blocks/model/vehicle-inspection.model';
import {ISafetyProfile, SafetyProfile} from 'app/blocks/model/safety-profile.model';
import {IRun, Run} from 'app/blocks/model/run.model';
import {IDriverType, DriverType} from 'app/blocks/model/driver-type.model';
import {ITpAssetProfile, TpAssetProfile} from 'app/blocks/model/tp-asset-profile.model';
import {ITpVehicleSkill, TpVehicleSkill} from 'app/blocks/model/tp-vehicle-skill.model';
import {ITpVehicleType, TpVehicleType} from 'app/blocks/model/tp-vehicle-type.model';
import {IPriceList, PriceList} from 'app/blocks/model/price-list.model';
import {Principal} from 'app/core/auth/principal.service';
import {IPayment, Payment} from 'app/blocks/model/payment.model';
import {IInvoice, Invoice} from 'app/blocks/model/invoice.model';
import {IVatType, VatType} from 'app/blocks/model/vat-type-model';
import {TrafficSheetLeg} from 'app/blocks/model/traffic-sheet-legs.model';
import {IUserEntity, UserEntity} from 'app/blocks/model/user-entity.model';
import {UpdatePaymentAllocated} from 'app/blocks/store/actions/payment-actions';
import {ITrafficArea, TrafficArea} from 'app/blocks/model/traffic-area.model';
import {ControlArea, IControlArea} from 'app/blocks/model/control-area.model';
import {CreditNote, ICreditNote} from 'app/blocks/model/credit-note.model';
import {UpdateCreditNoteAllocated} from 'app/blocks/store/actions/credit-note-actions';
import {CustomerTransaction, ICustomerTransaction} from 'app/blocks/model/customer-transaction.model';
import {ITeam, Team} from 'app/blocks/model/team.model';
import {ILateReason, LateReason} from 'app/blocks/model/late-reason.model';
import {IClausedDeliveryReason, ClausedDeliveryReason} from 'app/blocks/model/claused-delivery-reason.model';
import {defaultLegTrafficSheetState} from 'app/blocks/store/state-model/traffic-sheet-state-model';
import {
    CreateHaulierOrder,
    CreateMultipleHaulierOrder,
    ICreateHaulierOrder,
    ICreateMultipleHaulierOrder,
    IUpdateHaulierOrder,
    UpdateHaulierOrder
} from 'app/blocks/store/actions/haulier-order-actions';
import {HmFile, IHmFile} from 'app/blocks/model/hm-file.model';
import {HaulierOrderService} from 'app/blocks/service/api/haulier-order.service';
import {ILastCallIn, LastCallIn} from 'app/blocks/model/last-call-in.model';
import {IPodMetadata, PodMetadata} from 'app/blocks/model/pod-metadata.model';
import {
    CallInLeg,
    Communicate,
    DropResourceOnLeg,
    ICallInLeg,
    ILegActions,
    ITravelNotes,
    ResourceLegs,
    SelectAllLegTrafficSheets,
    SetTravelNotes,
    SubContract,
    UnCommunicate,
    UnResourceLegs,
    UnselectAllLegTrafficSheets,
    UnSubContractLegs
} from 'app/blocks/store/actions/traffic-sheet-actions';
import {DriverResource} from 'app/blocks/resource/driver-resource';
import {LegService} from 'app/blocks/service/api/leg-service';
import {EntityView} from 'app/blocks/util/entity-view';
import {TrafficSheetResource} from 'app/blocks/resource/traffic-sheet-resource';
import {Leg} from 'app/blocks/model/leg.model';
import {LegResource} from 'app/blocks/resource/leg-resource';
import {OrganisationService} from 'app/blocks/service/api/organisation.service';
import {RunResource} from 'app/blocks/resource/run-resource';
import {DriverSubType, IDriverSubType} from 'app/blocks/model/driver-sub-type.model';
import {ExpensePaymentMethod, IExpensePaymentMethod} from 'app/blocks/model/expense-payment-method';
import {DriverExpenses, IDriverExpenses} from 'app/blocks/model/driver-expenses';
import {DriverExpenseResource} from 'app/blocks/resource/driver-expense-resource';
import {IOrderGroup, OrderGroup} from 'app/blocks/model/order-group.model';
import {IManifest, Manifest} from 'app/blocks/model/manifest.model';
import {DriverService} from 'app/blocks/service/api/driver.service';
import {CreateOrganisation, ICreateOrganisation, IUpdateOrganisation, UpdateOrganisation} from 'app/blocks/store/actions/organisation-actions';
import {SubContractService} from 'app/blocks/service/api/sub-contract.service';
import {RunService} from 'app/blocks/service/api/run.service';
import {IPreInvoiceBatch, PreInvoiceBatch} from 'app/blocks/model/pre-invoice-batch.model';
import {ContainerType, IContainerType} from 'app/blocks/model/container-type.model';
import {UserDefaultService} from 'app/blocks/service/api/user-default.service';
import {IShiftType, ShiftType} from 'app/blocks/model/shift-type.model';
import {CustomListStateModel, defaultCustomListState} from 'app/blocks/store/state-model/custom-list-state-model';
import {IPerson, Person} from 'app/blocks/model/person.model';
import {FilterSettingService} from 'app/blocks/service/api/filter-setting.service';
import {IRole, Role} from 'app/blocks/model/role.model';
import {ISubcontractedWork, SubcontractedWork} from 'app/blocks/model/subcontracted-work.model';
import {Execution, IExecution} from 'app/blocks/model/execution.model';
import {ITenant, Tenant} from 'app/blocks/model/tenant.model';
import {IProfile, Profile} from 'app/blocks/model/profile.model';
import {Backup, IBackup} from 'app/blocks/model/backup.model';
import {IRestore, Restore} from 'app/blocks/model/restore.model';
import {SuperUserService} from 'app/core/auth/super-user.service';
import {DriverMessage, IDriverMessage} from 'app/blocks/model/driver-message.model';
import {AdvanceQuery, IAdvanceQuery} from 'app/blocks/model/advance-query.model';
import {EmailWorkflow, IEmailWorkflow} from 'app/blocks/model/email-workflow.model';
import {AppVersion, IAppVersion} from 'app/blocks/model/app-version.model';
import {AuthServerProvider} from 'app/core/auth/auth-jwt.service';
import {ITranslation, Translation} from 'app/blocks/model/translation.model';
import {RunCreationParameters} from 'app/blocks/model/run-creation-parameters.model';
import {IResourceIcon, ResourceIcon} from 'app/blocks/model/resource-icon.model';
import {ISystemSurcharge, SystemSurcharge} from 'app/blocks/model/system-surcharge.model';
import {AsyncRequest, IAsyncRequest} from 'app/blocks/model/async_request.model';
import {DriverRequestType, IDriverRequestType} from 'app/blocks/model/driver-request-type.model';
import {DriverRequest, IDriverRequest} from 'app/blocks/model/driver-request.model';
import {IPodAudit, PodAudit} from 'app/blocks/model/pod-audit.model';
import {IMultiTrunk, MultiTrunk} from 'app/blocks/model/multi-trunk.model';
import {DriverStartDayLog, IDriverStartDayLog} from 'app/blocks/model/driver-start-day-log';
import {DbUser, IDbUser} from 'app/blocks/model/dbuser.model';
import {LastCallInResource} from 'app/blocks/resource/last-call-in-resource';
import {DriverBreak, IDriverBreak} from 'app/blocks/model/driver-break.model';
import {LoadingSheet, ILoadingSheet} from 'app/blocks/model/loading-sheet.model';
import {DeviationReason} from 'app/blocks/model/deviation-reason.model';
import {OrderGroupService} from 'app/blocks/service/api/order-group.service';
import {TrafficSheetResourcesService} from 'app/blocks/service/api/traffic-sheet-resources.service';
import {PreInvoiceBatchService} from 'app/blocks/service/api/pre-invoice-batch.service';

export interface EntitiesStateModel {
    [Resource.COUNTRIES]: PartialEntityStateModel<ICountry>;
    [Resource.CURRENCIES]: PartialEntityStateModel<ICurrency>;
    [Resource.VEHICLES]: EntityStateModel<IVehicle>;
    [Resource.VEHICLE_CLASSES]: EntityStateModel<IVehicleClass>;
    [Resource.TRAILERS]: EntityStateModel<ITrailer>;
    [Resource.TRAILER_TYPES]: EntityStateModel<ITrailerType>;
    [Resource.HANDHELD_PROFILES]: EntityStateModel<IHandheldProfile>;
    [Resource.HAULIER_ORDERS]: EntityStateModel<IHaulierOrder>;
    [Resource.CUSTOM_GOODS_TYPES]: EntityStateModel<ICustomGoodsType>;
    [Resource.ORGANISATIONS]: EntityStateModel<IOrganisation>;
    [Resource.FUEL_SURCHARGE_GROUPS]: EntityStateModel<IFuelSurchargeGroup>;
    [Resource.FUEL_SURCHARGES]: InlineEntityStateModel<IFuelSurcharge>;
    [Resource.EXCHANGE_RATES]: InlineEntityStateModel<IExchangeRate>;
    [Resource.CONTACT_INFOS]: InlineEntityStateModel<IContactInfo>;
    [Resource.EXPENSE_TYPES]: InlineEntityStateModel<IExpenseType>;
    [Resource.EVENT_TYPES]: InlineEntityStateModel<IExchangeRate>;
    [Resource.SERVICE_LEVELS]: InlineEntityStateModel<IServiceLevel>;
    [Resource.LOCATION_POINTS]: EntityStateModel<ILocationPoint>;
    [Resource.DRIVERS]: EntityStateModel<IDriver>;
    [Resource.PACKAGE_RETURNS]: EntityStateModel<IPackageReturns>;
    [Resource.PAYMENTS]: EntityStateModel<IPayment>;
    [Resource.INVOICES]: EntityStateModel<IInvoice>;
    [Resource.VEHICLE_INSPECTION_REPORTS]: EntityStateModel<IVehicleInspectionReport>;
    [Resource.SAFETY_PROFILES]: EntityStateModel<ISafetyProfile>;
    [Resource.RUNS]: EntityStateModel<IRun>;
    [Resource.RESOURCE_EVENTS]: InlineEntityStateModel<IResourceEvent>;
    [Resource.NOMINAL_CODES]: InlineEntityStateModel<INominalCode>;
    [Resource.DRIVER_TYPES]: EntityStateModel<IDriverType>;
    [Resource.ROLES]: EntityStateModel<IRole>;
    [Resource.BUSINESS_TYPES]: EntityStateModel<IBusinessType>;
    [Resource.ATTEMPTED_DELIVERY]: EntityStateModel<IAttemptedDelivery>;
    [Resource.EXTRA_TYPES]: EntityStateModel<IExtraType>;
    [Resource.EXTRAS]: EntityStateModel<IExtra>;
    [Resource.CREDIT_NOTES]: EntityStateModel<ICreditNote>;
    [Resource.METRICS]: PartialEntityStateModel<IMetric>;
    [Resource.SCALES]: EntityStateModel<IScale>;
    [Resource.ORGANISATION_EXTRA_TYPES]: InlineEntityStateModel<IOrganisationExtraType>;
    [Resource.TP_ASSET_PROFILES]: EntityStateModel<ITpAssetProfile>;
    [Resource.TP_VEHICLE_SKILLS]: InlineEntityStateModel<ITpVehicleSkill>;
    [Resource.TP_VEHICLE_TYPES]: EntityStateModel<ITpVehicleType>;
    [Resource.RESOURCE_EVENT_TYPES]: EntityStateModel<IResourceEventType>;
    [Resource.PRICE_LISTS]: EntityStateModel<IPriceList>;
    [Resource.ZONE_MAPS]: EntityStateModel<IZoneMap>;
    [Resource.VAT_TYPE]: EntityStateModel<IVatType>;
    [Resource.LEG_TRAFFIC_SHEET]: EntityStateModel<TrafficSheetLeg>;
    [Resource.LEGS]: EntityStateModel<Leg>;
    [Resource.DRIVER_MESSAGES]: EntityStateModel<IDriverMessage>;

    [Resource.LAST_CALL_IN]: InlineEntityStateModel<ILastCallIn>;
    [Resource.USERS]: EntityStateModel<IUserEntity>;
    // [Resource.TRAFFIC_AREAS]: InlineEntityStateModel<ITrafficArea>;
    [Resource.TRAFFIC_AREAS]: EntityStateModel<ITrafficArea>;

    [Resource.CONTROL_AREAS]: EntityStateModel<IControlArea>;
    [Resource.CUSTOMER_TRANSACTIONS]: EntityStateModel<ICustomerTransaction>;
    [Resource.TEAMS]: EntityStateModel<ITeam>;
    [Resource.LATE_REASONS]: InlineEntityStateModel<ILateReason>;
    [Resource.CLAUSED_DELIVERY_REASONS]: InlineEntityStateModel<IClausedDeliveryReason>;
    [Resource.PODS]: InlineEntityStateModel<IPodMetadata>;
    [Resource.HM_FILES]: EntityStateModel<IHmFile>;
    [Resource.DRIVER_SUB_TYPES]: InlineEntityStateModel<IDriverSubType>;
    [Resource.EXPENSE_PAYMENT_METHOD]: InlineEntityStateModel<IExpensePaymentMethod>;
    [Resource.EXPENSES]: EntityStateModel<IDriverExpenses>;
    [Resource.ORDER_GROUPS]: EntityStateModel<IOrderGroup>;
    [Resource.MANIFESTS]: EntityStateModel<IManifest>;
    [Resource.PRE_INVOICE_BATCHS]: EntityStateModel<IPreInvoiceBatch>;
    [Resource.CONTAINER_TYPES]: EntityStateModel<IContainerType>;
    [Resource.SHIFT_TYPES]: EntityStateModel<IShiftType>;
    [Resource.OUTSTANDING_CALL_INS]: CustomListStateModel;
    [Resource.PERSONS]: EntityStateModel<IPerson>;
    [Resource.SUBCONTRACTED_WORK]: EntityStateModel<ISubcontractedWork>;
    [Resource.EXECUTIONS]: EntityStateModel<IExecution>;
    [Resource.TENANTS]: EntityStateModel<ITenant>;
    [Resource.PROFILES]: EntityStateModel<IProfile>;
    [Resource.BACKUPS]: EntityStateModel<IBackup>;
    [Resource.RESTORES]: EntityStateModel<IRestore>;
    [Resource.ADVANCE_QUERIES]: EntityStateModel<IAdvanceQuery>;
    [Resource.EMAIL_WORKFLOW]: EntityStateModel<IEmailWorkflow>;
    [Resource.READY_TO_INVOICE_ORDERS]: EntityStateModel<IHaulierOrder>;
    [Resource.MARK_READY_FOR_INVOICE_ORDERS]: EntityStateModel<IHaulierOrder>;
    [Resource.APP_VERSION]: EntityStateModel<IAppVersion>;
    [Resource.DB_USER]: EntityStateModel<IDbUser>;
    [Resource.TRANSLATION]: EntityStateModel<ITranslation>;
    [Resource.DAILY_MANIFEST]: EntityStateModel<IHaulierOrder>;
    [Resource.RESOURCE_ICONS]: EntityStateModel<IResourceIcon>;
    [Resource.SYSTEM_SURCHARGE]: EntityStateModel<ISystemSurcharge>;
    [Resource.ASYNC_REQUESTS]: EntityStateModel<IAsyncRequest>;
    [Resource.DRIVER_REQUEST_TYPES]: EntityStateModel<IDriverRequestType>;
    [Resource.DRIVER_REQUESTS]: EntityStateModel<IDriverRequest>;
    [Resource.POD_AUDIT]: EntityStateModel<IPodAudit>;
    [Resource.MULTI_TRUNKS]: EntityStateModel<IMultiTrunk>;
    [Resource.DRIVER_START_DAY_LOG]: EntityStateModel<IDriverStartDayLog>;
    [Resource.DRIVER_BREAKS]: EntityStateModel<IDriverBreak>;
    [Resource.LOADING_SHEET]: EntityStateModel<ILoadingSheet>;
    [Resource.DEVIATION_REASON]: InlineEntityStateModel<DeviationReason>;
}

export function getDefaultEntitiesState(): EntitiesStateModel {
    return {
        [Resource.COUNTRIES]: defaultPartialEntityState(),
        [Resource.CURRENCIES]: defaultPartialEntityState(),
        [Resource.VEHICLES]: defaultEntityState(() => new Vehicle()),
        [Resource.VEHICLE_CLASSES]: defaultEntityState(() => new VehicleClass()),
        [Resource.TRAILERS]: defaultEntityState(() => new Trailer()),
        [Resource.TRAILER_TYPES]: defaultEntityState(() => new TrailerType()),
        [Resource.HANDHELD_PROFILES]: defaultEntityState(() => new HandheldProfile()),
        [Resource.DRIVER_MESSAGES]: defaultEntityState(() => new DriverMessage()),
        [Resource.PACKAGE_RETURNS]: defaultEntityState(() => new PackageReturns()),
        [Resource.HAULIER_ORDERS]: defaultEntityState(() => new HaulierOrder(), true),
        [Resource.ORGANISATIONS]: defaultEntityState(() => new Organisation()),
        [Resource.CUSTOM_GOODS_TYPES]: defaultEntityState(() => new CustomGoodsType()),
        [Resource.FUEL_SURCHARGE_GROUPS]: defaultEntityState(() => new FuelSurchargeGroup()),
        [Resource.ROLES]: defaultEntityState(() => new Role()),
        [Resource.FUEL_SURCHARGES]: defaultInlineEntityState(() => new FuelSurcharge()),
        [Resource.EXCHANGE_RATES]: defaultInlineEntityState(() => new ExchangeRate()),
        [Resource.CONTACT_INFOS]: defaultInlineEntityState(() => new ContactInfo()),
        [Resource.SERVICE_LEVELS]: defaultInlineEntityState(() => new ServiceLevel()),
        [Resource.EXPENSE_TYPES]: defaultInlineEntityState(() => new ExpenseType()),
        [Resource.EVENT_TYPES]: defaultInlineEntityState(() => new EventType()),
        [Resource.LOCATION_POINTS]: defaultEntityState(() => new LocationPoint()),
        [Resource.DRIVERS]: defaultEntityState(() => new Driver()),
        [Resource.VEHICLE_INSPECTION_REPORTS]: defaultEntityState(() => new VehicleInspectionReport(), true),
        [Resource.SAFETY_PROFILES]: defaultEntityState(() => new SafetyProfile()),
        [Resource.RUNS]: defaultEntityState(() => new Run()),
        [Resource.RESOURCE_EVENTS]: defaultInlineEntityState(() => new ResourceEvent()),
        [Resource.NOMINAL_CODES]: defaultInlineEntityState(() => new NominalCode()),
        [Resource.DRIVER_TYPES]: defaultEntityState(() => new DriverType()),
        [Resource.BUSINESS_TYPES]: defaultEntityState(() => new BusinessType()),
        [Resource.EXTRA_TYPES]: defaultEntityState(() => new ExtraType()),
        [Resource.EXTRAS]: defaultEntityState(() => new Extra()),
        [Resource.METRICS]: defaultPartialEntityState(),
        [Resource.SCALES]: defaultEntityState(() => new Scale()),
        [Resource.ORGANISATION_EXTRA_TYPES]: defaultInlineEntityState(() => new OrganisationExtraType()),
        [Resource.TP_ASSET_PROFILES]: defaultEntityState(() => new TpAssetProfile()),
        [Resource.TP_VEHICLE_SKILLS]: defaultInlineEntityState(() => new TpVehicleSkill()),
        [Resource.TP_VEHICLE_TYPES]: defaultEntityState(() => new TpVehicleType()),
        [Resource.RESOURCE_EVENT_TYPES]: defaultEntityState(() => new ResourceEventType()),
        [Resource.PRICE_LISTS]: defaultEntityState(() => new PriceList()),
        [Resource.ZONE_MAPS]: defaultEntityState(() => new ZoneMap()),
        [Resource.PAYMENTS]: defaultEntityState(() => new Payment()),
        [Resource.INVOICES]: defaultEntityState(() => new Invoice(), true),
        [Resource.VAT_TYPE]: defaultEntityState(() => new VatType()),
        [Resource.LEG_TRAFFIC_SHEET]: defaultLegTrafficSheetState(() => new TrafficSheetLeg()),
        [Resource.LEGS]: defaultEntityState(() => new Leg()),
        [Resource.LAST_CALL_IN]: defaultInlineEntityState(() => new LastCallIn()),
        [Resource.USERS]: defaultEntityState(() => new UserEntity()),
        [Resource.TRAFFIC_AREAS]: defaultEntityState(() => new TrafficArea()),
        [Resource.CONTROL_AREAS]: defaultEntityState(() => new ControlArea()),
        [Resource.CREDIT_NOTES]: defaultEntityState(() => new CreditNote()),
        [Resource.CUSTOMER_TRANSACTIONS]: defaultEntityState(() => new CustomerTransaction(), true),
        [Resource.TEAMS]: defaultEntityState(() => new Team()),
        [Resource.LATE_REASONS]: defaultInlineEntityState(() => new LateReason()),
        [Resource.CLAUSED_DELIVERY_REASONS]: defaultInlineEntityState(() => new ClausedDeliveryReason()),
        [Resource.PODS]: defaultInlineEntityState(() => new PodMetadata()),
        [Resource.HM_FILES]: defaultEntityState(() => new HmFile()),
        [Resource.DRIVER_SUB_TYPES]: defaultInlineEntityState(() => new DriverSubType()),
        [Resource.EXPENSE_PAYMENT_METHOD]: defaultInlineEntityState(() => new ExpensePaymentMethod()),
        [Resource.EXPENSES]: defaultEntityState(() => new DriverExpenses()),
        [Resource.ORDER_GROUPS]: defaultEntityState(() => new OrderGroup()),
        [Resource.MANIFESTS]: defaultEntityState(() => new Manifest(), true),
        [Resource.PRE_INVOICE_BATCHS]: defaultEntityState(() => new PreInvoiceBatch()),
        [Resource.CONTAINER_TYPES]: defaultEntityState(() => new ContainerType()),
        [Resource.SHIFT_TYPES]: defaultEntityState(() => new ShiftType()),
        [Resource.OUTSTANDING_CALL_INS]: defaultCustomListState(),
        [Resource.PERSONS]: defaultEntityState(() => new Person()),
        [Resource.ATTEMPTED_DELIVERY]: defaultEntityState(() => new AttemptedDelivery()),
        [Resource.SUBCONTRACTED_WORK]: defaultEntityState(() => new SubcontractedWork()),
        [Resource.EXECUTIONS]: defaultEntityState(() => new Execution()),
        [Resource.TENANTS]: defaultEntityState(() => new Tenant()),
        [Resource.BACKUPS]: defaultEntityState(() => new Backup(), true),
        [Resource.RESTORES]: defaultEntityState(() => new Restore(), true),
        [Resource.PROFILES]: defaultEntityState(() => new Profile()),
        [Resource.ADVANCE_QUERIES]: defaultEntityState(() => new AdvanceQuery()),
        [Resource.EMAIL_WORKFLOW]: defaultEntityState(() => new EmailWorkflow()),
        [Resource.READY_TO_INVOICE_ORDERS]: defaultEntityState(() => new HaulierOrder()),
        [Resource.MARK_READY_FOR_INVOICE_ORDERS]: defaultEntityState(() => new HaulierOrder()),
        [Resource.APP_VERSION]: defaultEntityState(() => new AppVersion()),
        [Resource.DB_USER]: defaultEntityState(() => new DbUser()),
        [Resource.TRANSLATION]: defaultEntityState(() => new Translation()),
        [Resource.DAILY_MANIFEST]: defaultEntityState(() => new HaulierOrder(), true),
        [Resource.RESOURCE_ICONS]: defaultEntityState(() => new ResourceIcon()),
        [Resource.ASYNC_REQUESTS]: defaultEntityState(() => new AsyncRequest(), true),
        [Resource.SYSTEM_SURCHARGE]: defaultEntityState(() => new SystemSurcharge()),
        [Resource.DRIVER_REQUEST_TYPES]: defaultEntityState(() => new DriverRequestType()),
        [Resource.DRIVER_REQUESTS]: defaultEntityState(() => new DriverRequest()),
        [Resource.POD_AUDIT]: defaultEntityState(() => new PodAudit(), true),
        [Resource.MULTI_TRUNKS]: defaultEntityState(() => new MultiTrunk(), true),
        [Resource.DRIVER_START_DAY_LOG]: defaultEntityState(() => new DriverStartDayLog(), true),
        [Resource.DRIVER_BREAKS]: defaultEntityState(() => new DriverBreak(), true),
        [Resource.LOADING_SHEET]: defaultEntityState(() => new LoadingSheet(), true),
        [Resource.DEVIATION_REASON]: defaultInlineEntityState(() => new DeviationReason())
    };
}

@State<EntitiesStateModel>({
    name: 'entities',
    defaults: getDefaultEntitiesState()
})
@Injectable({
    providedIn: 'root'
})
export class EntitiesState {
    isSuperuser = false;
    private tenantName: string;

    constructor(
        private _entityActionHandler: EntityActionHandler,
        private authServerProvider: AuthServerProvider,
        private _alertService: AlertService,
        private _settingMetaDataService: SettingMetaDataService,
        private _trafficSheetResource: TrafficSheetResource,
        private _lastCallInResource: LastCallInResource,
        private _legResource: LegResource,
        private _legService: LegService,
        private _subContractorService: SubContractService,
        private _driverService: DriverService,
        private _driverResource: DriverResource,
        private tsService: TrafficSheetResourcesService,
        private _runResource: RunResource,
        private superUserService: SuperUserService,
        private _haulierOrderService?: HaulierOrderService,
        private _orderGroupService?: OrderGroupService,
        private _organisationService?: OrganisationService,
        protected _principalService?: Principal,
        private _userDefaultService?: UserDefaultService,
        private _filterSettingService?: FilterSettingService,
        private preInvoiceBatchService?: PreInvoiceBatchService
    ) {
        this._entityActionHandler.init();
        let tenant = this.authServerProvider.getTenant();
        this.tenantName = tenant ? tenant.toLocaleLowerCase() : null;
    }

    @Action(GetEntityList)
    async getEntityList(ctx: StateContext<EntitiesStateModel>, action: IGetEntityList): Promise<any[]> {
        const prevState = ctx.getState();
        const resourceName: string = action.entityName ?? action.resource.name;
        let viewParams = prevState[resourceName]['listView']['viewParams'];

        if (DEBUG_PAGE_PARAMS) {
            console.log(`Old view are params: ${JSON.stringify(viewParams)}`);
            console.log(`New view are params: ${JSON.stringify(action.viewParams)}`);
        }

        this.isSuperuser = this.superUserService.getIsSuperUser();

        if (action.viewParams) {
            viewParams = action.viewParams;
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const listView = draft[resourceName]['listView'];
                    listView.viewParams = viewParams;
                })
            );
        }

        let api: (request: any) => Promise<HttpResponse<any[]>>;
        if (this._principalService && this._principalService.isUserCustomer()) {
            api = viewParams.filter ? action.resource.api.customerSearchHttp : action.resource.api.customerQueryHttp;
        } else {
            api = viewParams.filter ? action.resource.api.searchHttp : action.resource.api.queryHttp;
        }

        return this._fetchEntityList(ctx, action.resource, viewParams, api, action.primaryFilter, resourceName);
    }

    @Action(ChangeEntityListViewParams)
    changeBranchListViewParams(ctx: StateContext<EntitiesStateModel>, action: IChangeEntityListViewParams): void {
        if (!this.isSuperuser) {
            this._userDefaultService.createUpdateUserDefault(JSON.stringify(action.viewParams), action.resourceName ?? action.resource.name);
        }
        this.getEntityList(ctx, action as IGetEntityList);
    }

    @Action(ChangeEntityFilterParams)
    changeBranchListViewParamsOnly(ctx: StateContext<EntitiesStateModel>, action: any): void {
        if (action.filterParams) {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const listView = draft[action.resourceName]['listView'];
                    listView.viewParams.filterParams = action.filterParams;
                })
            );
        }
    }

    @Action(GetNewEntity)
    async newEntity(ctx: StateContext<EntitiesStateModel>, action: IGetNewEntity): Promise<any> {
        const entity = action.resource.newInstance();
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.currentEntity = entity;
                entityView.statusSaving = false;
                entityView.statusDeleting = false;
                entityView.selectedTabIndex = 0;
            })
        );
        return entity;
    }

    @Action(GetNewEntityWithSettings)
    async newEntityWithSettings(ctx: StateContext<EntitiesStateModel>, action: IGetNewEntityWithSettings): Promise<any> {
        const entity = action.resource.newInstance();
        const metaData = await this._settingMetaDataService.getResourceSettingMetaData(action.settingResourceName);
        entity['readSettings'] = this.transformingData(metaData);
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.currentEntity = entity;
                entityView.statusSaving = false;
                entityView.statusDeleting = false;
                entityView.selectedTabIndex = 0;
            })
        );

        return entity;
    }

    /**
     *
     * This is an extremely distrubing hack which is needed to cater for the weird mash of datatypes
     * being expected in the frontend. Luckily this only needs to be called on the create screen.
     *
     */
    transformingData(settingsMetaData: any[]): any {
        const settings2Return: any[] = [];

        if (settingsMetaData) {
            if (settingsMetaData[0].settingDef === undefined) {
                return settingsMetaData;
            }
        }

        settingsMetaData.forEach((value) => {
            const settingMetaDataNew = {
                key: value.key,
                valueType: value.settingDef.valueType,
                settingValue: value.settingDef.defaultValue,
                maxValue: value.settingDef.maxValue,
                minValue: value.settingDef.minValue,
                maxLength: value.settingDef.maxLength,
                minLength: value.settingDef.minLength,
                defaultValue: value.settingDef.defaultValue,
                sequence: value.sequence,
                group: 'MOBILE_APP',
                subGroup: value.settingDef.category || null,
                mandatory: value.settingDef.mandatory,
                enabled: false,
                parentSetting: value.parentSetting,
                selectedOptions: value.selectedOptions || [],
                selectedOptionKey: value.selectedOptionKey
            };
            settings2Return.push(settingMetaDataNew);
        });

        return settings2Return;
    }

    @Action(UpdateCreditNoteAllocated)
    async updateCreditNoteAllocated(ctx: StateContext<EntitiesStateModel>, action: UpdateCreditNoteAllocated): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityList = draft[action.resource.name]['listView'].entityList as any[];
                entityList.forEach((data: ICreditNote, index: number) => {
                    if (data.id === action.entity.id) {
                        entityList[index].allocatedAmount = action.entity.allocatedAmount;
                    }
                });
            })
        );
    }

    @Action(UpdateEntity)
    async updateEntity(ctx: StateContext<EntitiesStateModel>, action: IUpdateEntity): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const entity = await action.resource.api.update(action.entity);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdated.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    private resetUpdateState(ctx: StateContext<EntitiesStateModel>, action: IEntityAction): void {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = false;
                entityView.statusDeleting = false;
            })
        );
    }

    @Action(DuplicateEntity)
    async duplicateEntity(ctx: StateContext<EntitiesStateModel>, action: IDuplicateEntity): Promise<any> {
        const entity = await action.resource.api.find(action.id);
        entity.id = null;
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.currentEntity = entity;
            })
        );

        return entity;
    }

    @Action(UpdateHaulierOrder)
    async updateHaulierOrder(ctx: StateContext<EntitiesStateModel>, action: IUpdateHaulierOrder): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            let entity = await action.resource.api.update(action.entity);

            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdated.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(CreateHaulierOrder)
    async createHaulierOrder(ctx: StateContext<EntitiesStateModel>, action: ICreateHaulierOrder): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const uploadDocuments = action.uploadDocuments as IHmFile[];
            let entity = await action.resource.api.create(action.entity);
            if (uploadDocuments.length > 0) {
                for (const file of uploadDocuments) {
                    await this._haulierOrderService.uploadFile(file.name, file.multipartFile, null, entity.id, file.fileType);
                }
            }
            entity = await action.resource.api.find(entity.id);

            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {discriminator: entity.discriminator});

            let orderIds = [];

            if (action.entity.splitOnCreation) {
                await this._haulierOrderService.splitForGoods(entity.id).then((splitOrderIds) => {
                    this._alertService.showSuccess(`${action.resource.name}.messages.orderSplitSuccessfully.message`);

                    orderIds = splitOrderIds;
                    const index = orderIds.findIndex((id) => id === entity.id);
                    if (index !== -1) {
                        orderIds.splice(index, 1);
                    }
                });
            } else {
                orderIds.push(entity.id);
            }

            if (action.entity.createRun) {
                const creationParams: RunCreationParameters = {orderIds: orderIds, optimizeRun: action.optimizeRun, sequenceOptimised: true};
                await this._haulierOrderService.createRunForUnplanned(creationParams).then((run) => {
                    const runId = run.id;
                    if (this.tenantName !== 'uitesttenant' && this.tenantName !== 'uitesttenant2') {
                        this.tsService.openRunView(runId);
                    }
                    this._alertService.showSuccess(`${action.resource.name}.messages.runCreatedSuccessfully.message`, null, {
                        discriminator: runId
                    });
                });
            }

            if (action.entity.prePaid) {
                await this._haulierOrderService.createBatchesFromIds(orderIds, null).then((result) => {
                    if (result.status === 200) {
                        this.preInvoiceBatchService.approvePreInvoiceBatches(result.body).then((result) => {
                            if (result.status === 200) {
                                this._alertService.showSuccess(`haulierOrders.messages.batchesApprovedSuccessfully.message`);
                                if (result.body) {
                                    result.body.forEach((invoiceId) => {
                                        window.open('/setup/invoices/' + invoiceId + '?hideSideBar=true', '_blank').focus();
                                    });
                                }
                            }
                        });
                    }
                });
            }
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(CreateMultipleHaulierOrder)
    async createMultipleHaulierOrder(ctx: StateContext<EntitiesStateModel>, action: ICreateMultipleHaulierOrder): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const createdOrders = [];
            const uploadDocuments = action.uploadDocuments as IHmFile[];
            for (let order of action.entitys) {
                let entity = await action.resource.api.create(order);
                if (uploadDocuments.length > 0) {
                    for (const file of uploadDocuments) {
                        await this._haulierOrderService.uploadFile(file.name, file.multipartFile, null, entity.id, file.fileType);
                    }
                }
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const entityView = draft[action.resource.name]['entityView'];
                        entityView.currentEntity = entity;
                        entityView.statusSaving = false;
                        entityView.statusDeleting = false;

                        const state = ctx.getState();
                        for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                            draft[action.resource.name].partialEntity[listName].loaded = false;
                        }
                    })
                );
                createdOrders.push(entity);
            }
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.entityIds = createdOrders.map((order) => order.id);
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {
                discriminator: createdOrders.map((order) => order.orderNo).join(', ')
            });

            this._orderGroupService.create(new OrderGroup({orderIds: createdOrders.map((order) => order.id)})).then((orderGroup) => {
                if (action.entitys[0].createRun) {
                    const creationParams: RunCreationParameters = {
                        orderIds: createdOrders.map((order) => order.id),
                        optimizeRun: action.optimizeRun,
                        sequenceOptimised: true
                    };
                    this._haulierOrderService.createRunForUnplanned(creationParams).then((run) => {
                        const runId = run.id;
                        if (this.tenantName !== 'uitesttenant' && this.tenantName !== 'uitesttenant2') {
                            window.open(`/runs/runs/${runId}`, '_blank');
                        }
                        this._alertService.showSuccess(`${action.resource.name}.messages.runCreatedSuccessfully.message`, null, {
                            discriminator: runId
                        });
                    });
                }
                return createdOrders;
            });
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(CreateEntity)
    async createEntity(ctx: StateContext<EntitiesStateModel>, action: ICreateEntity): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            const entity = await action.resource.api.create(action.entity);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(GetEntity)
    async getEntity(ctx: StateContext<EntitiesStateModel>, action: IGetEntity): Promise<any> {
        let entity;
        if (this._principalService && this._principalService.isUserCustomer()) {
            entity = await action.resource.api.customerFind(action.id);
        } else {
            entity = await action.resource.api.find(action.id);
        }
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                if (entityView.currentEntity && entityView.currentEntity.id !== entity.id) {
                    entityView.selectedTabIndex = 0;
                }
                entityView.currentEntity = entity;
                entityView.statusSaving = false;
                entityView.statusDeleting = false;
            })
        );
    }

    @Action(DeleteEntity)
    async deleteEntity(ctx: StateContext<EntitiesStateModel>, action: IDeleteEntity): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusDeleting = true;
            })
        );
        try {
            const entity = await action.resource.api.find(action.id);
            const response = await action.resource.api.deleteHttp(action.id, action.req);
            if (response.status >= 200 && response.status < 300) {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const entityView = draft[action.resource.name]['entityView'];
                        entityView.statusDeleting = false;
                        const selectedIds = draft[action.resource.name]['listView'].selectedIds;
                        draft[action.resource.name]['listView'].selectedIds = selectedIds.filter((id) => id !== action.id);

                        const state = ctx.getState();
                        for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                            draft[action.resource.name].partialEntity[listName].loaded = false;
                        }
                    })
                );
                this._alertService.showSuccess(`${action.resource.name}.messages.entityDeleted.message`, null, {
                    discriminator: entity.discriminator ? entity.discriminator : entity.id
                });
                ctx.dispatch(new GetEntityList(action.resource));
            }
            return true;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(DeleteInlineEntity)
    async deleteInlineEntity(ctx: StateContext<EntitiesStateModel>, action: IDeleteInlineEntity): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const listView = draft[action.resource.name]['listView'];
                listView.statusDeleting = true;
            })
        );
        try {
            const entity = await action.resource.api.find(action.id);
            const response = await action.resource.api.deleteHttp(action.id);
            if (response.status >= 200 && response.status < 300) {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const entityList = draft[action.resource.name]['listView'];
                        entityList.statusDeleting = false;
                        const selectedIds = draft[action.resource.name]['listView'].selectedIds;
                        draft[action.resource.name]['listView'].selectedIds = selectedIds.filter((id) => id !== action.id);

                        const state = ctx.getState();
                        for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                            draft[action.resource.name].partialEntity[listName].loaded = false;
                        }
                    })
                );
                this._alertService.showSuccess(`${action.resource.name}.messages.entityDeleted.message`, null, {discriminator: entity.discriminator});
                ctx.dispatch(new GetEntityList(action.resource));
            }
            return true;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(ActivateResource)
    async activateDriver(ctx: StateContext<EntitiesStateModel>, action: IActivateResource): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        const entity = await action.resource.api.find(action.id);
        try {
            const response = await action.resource.api.activateResource(action.id);
            if (response.status >= 200 && response.status < 300) {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        const entityView = draft[action.resource.name]['entityView'];
                        entityView.statusSaving = false;
                        const selectedIds = draft[action.resource.name]['listView'].selectedIds;
                        draft[action.resource.name]['listView'].selectedIds = selectedIds.filter((id) => id !== action.id);

                        const state = ctx.getState();
                        for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                            draft[action.resource.name].partialEntity[listName].loaded = false;
                        }
                    })
                );
                this._alertService.showSuccess(`${action.resource.name}.messages.entityActivated.message`, null, {
                    discriminator: entity.discriminator
                });
                ctx.dispatch(new GetEntityList(action.resource));
            }
            return true;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            if (err.error.detail === 'purgedDriver') {
                this._alertService.showError('drivers.messages.purgedDriverError.message', null, {discriminator: entity.discriminator});
            }
            throw err;
        }
    }

    @Action(CreateInlineEntity)
    async createInlineEntity(ctx: StateContext<EntitiesStateModel>, action: ICreateInlineEntity): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView']['statusSaving'] = true;
            })
        );
        try {
            const entity = await action.resource.api.create(action.entity);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {discriminator: entity.discriminator});
            this.resetInlineUpdateState(ctx, action);
            ctx.dispatch(new GetEntityList(action.resource));
        } catch (err) {
            this.resetInlineUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(UpdateInlineEntity)
    async updateInlineEntity(ctx: StateContext<EntitiesStateModel>, action: IUpdateInlineEntity): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView']['statusSaving'] = true;
            })
        );
        try {
            const entity = await action.resource.api.update(action.entity);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdated.message`, null, {discriminator: entity.discriminator});
            this.resetInlineUpdateState(ctx, action);
            ctx.dispatch(new GetEntityList(action.resource));
        } catch (err) {
            this.resetInlineUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(StartInlineEntityAdd)
    async startAddInlineEntity(ctx: StateContext<EntitiesStateModel>, action: IStartInlineEntityAdd): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView']['statusUserInput'] = true;
                draft[action.resource.name]['listView']['statusAdding'] = true;
            })
        );
        // Move to last page as well
    }

    private resetInlineUpdateState(ctx: StateContext<EntitiesStateModel>, action: IUpdateEntity): void {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView']['statusSaving'] = false;
                draft[action.resource.name]['listView']['statusAdding'] = false;
                draft[action.resource.name]['listView']['statusUserInput'] = false;
            })
        );
    }

    @Action(StartInlineEntityUpdate)
    async startInlineUpdateEntity(ctx: StateContext<EntitiesStateModel>, action: IStartInlineEntityUpdate): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView']['statusUserInput'] = true;
            })
        );
    }

    @Action(CancelInlineEntityUpdate)
    async cancelInlineUpdateEntity(ctx: StateContext<EntitiesStateModel>, action: ICancelInlineEntityUpdate): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView']['statusUserInput'] = false;
                draft[action.resource.name]['listView']['statusAdding'] = false;
            })
        );
    }

    @Action(SelectEntity)
    async selectEntity(ctx: StateContext<EntitiesStateModel>, action: ISelectEntity): Promise<any> {
        const prevState = ctx.getState();
        const entityName: string = action.entityName ?? action.resource.name;
        const oldSelectedIds: number[] = prevState[entityName]['listView'].selectedIds.map((id) => parseInt(id));
        const oldSelectedIdsCopy = oldSelectedIds.slice();
        let newEntityIds = action.entityIds.slice();
        newEntityIds = newEntityIds.filter((id: any) => !oldSelectedIds.includes(parseInt(id)));

        if (newEntityIds.length > 0) {
            oldSelectedIdsCopy.push(...newEntityIds);
            ctx.setState({
                ...prevState,
                [entityName]: {
                    ...prevState[entityName],
                    listView: {
                        ...prevState[entityName]['listView'],
                        selectedIds: oldSelectedIdsCopy
                    }
                }
            });
        }
    }

    @Action(SelectAllCurrentPageEntities)
    async selectAllCurrentPageEntities(ctx: StateContext<EntitiesStateModel>, action: ISelectAllCurrentPageEntities): Promise<any> {
        const prevState = ctx.getState();
        const entityName: string = action.entityName ?? action.resource.name;
        const entityList: any[] = prevState[entityName]['listView'].entityList;
        const prevSelectedIds: any[] = prevState[entityName]['listView'].selectedIds;
        const toSelectEntityIds = entityList.map((entity) => entity['id']).filter((id) => !prevSelectedIds.includes(parseInt(id)));
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[entityName]['listView'].selectedIds.push(...toSelectEntityIds);
            })
        );
    }

    @Action(UpdatePaymentAllocated)
    async updatePaymentAllocated(ctx: StateContext<EntitiesStateModel>, action: UpdatePaymentAllocated): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityList = draft[action.resource.name]['listView'].entityList as any[];
                entityList.forEach((data: IPayment, index: number) => {
                    if (data.id === action.entity.id) {
                        entityList[index].allocatedAmount = action.entity.allocatedAmount;
                    }
                });
            })
        );
    }

    @Action(UnselectAllCurrentPageEntities)
    async unselectAllCurrentPageEntities(ctx: StateContext<EntitiesStateModel>, action: IUnselectAllCurrentPageEntities): Promise<any> {
        const prevState = ctx.getState();
        const entityName: string = action.entityName ?? action.resource.name;
        const entityList: any[] = prevState[entityName]['listView'].entityList;
        const toUnselectEntityIds = entityList.map((entity) => parseInt(entity['id']));
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[entityName]['listView'].selectedIds = draft[entityName]['listView'].selectedIds.filter(
                    (id) => !toUnselectEntityIds.includes(parseInt(id))
                );
            })
        );
    }

    @Action(UnselectEntity)
    async unselectEntity(ctx: StateContext<EntitiesStateModel>, action: IUnselectEntity): Promise<any> {
        const prevState = ctx.getState();
        const entityName: string = action.entityName ?? action.resource.name;
        const selectedIds: number[] = prevState[entityName]['listView'].selectedIds.map((id) => parseInt(id));
        const newSelectedIds = selectedIds.filter((x: any) => !action.entityIds.includes(parseInt(x)));

        ctx.setState({
            ...prevState,
            [entityName]: {
                ...prevState[entityName],
                listView: {
                    ...prevState[entityName]['listView'],
                    selectedIds: newSelectedIds
                }
            }
        });
    }

    @Action(SelectEntityTabIndex)
    async selectEntityTabIndex(ctx: StateContext<EntitiesStateModel>, action: ISelectEntityTabIndex): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['entityView'].selectedTabIndex = action.tabIndex;
            })
        );
    }

    @Action(GetNamedEntityList)
    async getPartialEntityList(ctx: StateContext<EntitiesStateModel>, action: AbstractGetNamedEntityList): Promise<any[]> {
        const currentState = ctx.getState();
        if (action.forceReload || currentState[action.resource.name].partialEntity[action.name].loaded === false) {
            const namedList = await action.resource.api.queryPartial();
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    draft[action.resource.name].partialEntity[action.name] = {
                        list: namedList,
                        loaded: true
                    };
                })
            );
            return namedList;
        }
    }

    @Action(ExportEntities)
    async exportEntities(ctx: StateContext<EntitiesStateModel>, action: IExportEntities): Promise<any> {
        const response = await action.resource.api.export(action.ids, action.type, {
            ...action.params
        });
        if (response.status === 200) {
            const type = response.headers.get('Content-Type');
            saveAs(new Blob([response.body], {type}), action.filename);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityList: any[] = draft[action.resource.name]['listView']['entityList'];
                    for (const entity of entityList) {
                        if (action.ids.has(entity['id'])) {
                            entity['exported'] = true;
                        }
                    }
                })
            );
        }
    }

    @Action(ForceGetEntityList)
    async forceGetEntityList(ctx: StateContext<EntitiesStateModel>, action: IForceGetEntityList): Promise<any> {
        const resourceName: string = action.entityName ?? action.resource.name;
        const viewParams = ctx.getState()[resourceName]['listView']['viewParams'];
        let api: (request: any) => Promise<HttpResponse<any[]>>;
        api = viewParams.filter ? action.resource.api.searchHttp : action.resource.api.queryHttp;
        return this._fetchEntityList(ctx, action.resource, viewParams, api, action.primaryFilter, resourceName);
    }

    @Action(PopulateEntity)
    async populateEntity(ctx: StateContext<EntitiesStateModel>, action: IPopulateEntity): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.currentEntity = action.entity;
                entityView.statusSaving = false;
                entityView.statusDeleting = false;
            })
        );
        return action.entity;
    }

    @Action(PopulateEntityList)
    async populateEntityList(ctx: StateContext<EntitiesStateModel>, action: IPopulateEntityList): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const listView = draft[action.resource.name]['listView'];
                listView.entityList = action.list;
                listView.selectedIds = [];
                listView.statusSaving = false;
                listView.statusDeleting = false;
                listView.statusUserInput = false;
            })
        );
        return action.list;
    }

    @Action(ReplaceEntityListItem)
    async replaceEntityListItem(ctx: StateContext<EntitiesStateModel>, action: IReplaceEntityListItem): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const listView = draft[action.resource.name]['listView'];
                let index = 0;
                for (const entity of listView.entityList) {
                    if (entity.id === action.entity.id) {
                        break;
                    }
                    index++;
                }
                if (index < listView.entityList.length) {
                    listView.entityList.splice(index, 1, action.entity);
                }
            })
        );
        this.resetInlineUpdateState(ctx, action);
        return action.entity;
    }

    @Action(InsertEntityListItem)
    async insertEntityListItem(ctx: StateContext<EntitiesStateModel>, action: IInsertEntityListItem): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const listView = draft[action.resource.name]['listView'];
                if (action.index >= 0 && action.index < listView.entityList.length) {
                    listView.entityList.splice(action.index, 0, action.entity);
                } else {
                    if (listView.entityList.length == 0) {
                        listView.entityList.push(action.entity);
                    }
                }
            })
        );
        this.resetInlineUpdateState(ctx, action);
        return action.entity;
    }

    // TrafficSheet Actions:

    @Action(DropResourceOnLeg)
    async DropResourceOnLeg(ctx: StateContext<EntitiesStateModel>, action: ILegActions): Promise<void> {
        const actions: ILegActions = {
            resources: action.resource,
            ids: action.resource.id
        };
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                if (!draft) {
                    console.log('getstate() returned undefined ');
                } else {
                    const entityView = draft[this._trafficSheetResource.name].entityView;
                    entityView.statusSaving = true;
                }
            })
        );
        try {
            await this._legService.updateLegResourcesFromPlanningBoard(action.resource);
            ctx.dispatch(new GetEntityList(this._trafficSheetResource));
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[this._trafficSheetResource.name].entityView;
                    entityView.statusSaving = false;
                })
            );
        } catch (err) {
            const trafficSheetEntityAction: IEntityAction = {
                resource: this._trafficSheetResource,
                currentPage: EntityView.LIST
            };
            this.resetUpdateState(ctx, trafficSheetEntityAction);
            throw err;
        }
    }

    @Action(ResourceLegs)
    async resourceLegs(ctx: StateContext<EntitiesStateModel>, action: ILegActions): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                if (!draft) {
                    console.log('getstate() returned undefined ');
                } else {
                    const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                    trafficSheetEntityView.statusSaving = true;
                    const runEntityView = draft[this._runResource.name].entityView;
                    runEntityView.statusSaving = true;
                }
            })
        );
        try {
            await this._legService.resource(action.resources);
            let leg: Leg;
            if (action.resources.length > 0) {
                const id = action.resources[0].id;
                this._legService.find(id).then((entity) => {
                    leg = entity as Leg;
                    if (leg) {
                        ctx.dispatch(new GetEntity(this._runResource, leg.runId));
                    }
                });
            }
            ctx.dispatch(new GetEntityList(this._trafficSheetResource));
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    if (!draft) {
                        console.log('getstate() returned undefined ');
                    } else {
                        const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                        trafficSheetEntityView.statusSaving = false;
                        const runEntityView = draft[this._runResource.name].entityView;
                        runEntityView.statusSaving = false;
                    }
                })
            );
            return leg;
        } catch (err) {
            const runEntityAction: IEntityAction = {
                resource: this._runResource,
                currentPage: EntityView.VIEW
            };
            const trafficSheetEntityAction: IEntityAction = {
                resource: this._trafficSheetResource,
                currentPage: EntityView.LIST
            };
            this.resetUpdateState(ctx, runEntityAction);
            this.resetUpdateState(ctx, trafficSheetEntityAction);
            throw err;
        }
    }

    @Action(UnResourceLegs)
    async unResourceLegs(ctx: StateContext<EntitiesStateModel>, action: ILegActions): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                if (!draft) {
                    console.log('getstate() returned undefined ');
                } else {
                    const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                    trafficSheetEntityView.statusSaving = true;
                    const runEntityView = draft[this._runResource.name].entityView;
                    runEntityView.statusSaving = true;
                }
            })
        );
        try {
            await this._legService.unResourceLegs(action.ids);
            let leg: Leg;
            if (action.ids.length > 0) {
                const id = action.ids[0];
                this._legService.find(id).then((entity) => {
                    leg = entity as Leg;
                    if (leg) {
                        ctx.dispatch(new GetEntity(this._runResource, leg.runId));
                    }
                });
            }
            ctx.dispatch(new GetEntityList(this._trafficSheetResource));
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    if (!draft) {
                        console.log('getstate() returned undefined ');
                    } else {
                        const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                        trafficSheetEntityView.statusSaving = false;
                        const runEntityView = draft[this._runResource.name].entityView;
                        runEntityView.statusSaving = false;
                    }
                })
            );
            return leg;
        } catch (err) {
            const runEntityAction: IEntityAction = {
                resource: this._runResource,
                currentPage: EntityView.VIEW
            };
            const trafficSheetEntityAction: IEntityAction = {
                resource: this._trafficSheetResource,
                currentPage: EntityView.LIST
            };
            this.resetUpdateState(ctx, runEntityAction);
            this.resetUpdateState(ctx, trafficSheetEntityAction);
            throw err;
        }
    }
    @Action(UnSubContractLegs)
    async unSubContractLegs(ctx: StateContext<EntitiesStateModel>, action: ILegActions): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                if (!draft) {
                    console.log('getstate() returned undefined ');
                } else {
                    const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                    trafficSheetEntityView.statusSaving = true;
                    const runEntityView = draft[this._runResource.name].entityView;
                    runEntityView.statusSaving = true;
                }
            })
        );
        try {
            await this._subContractorService.unSubContractLegs(action.ids, action.sendCancellationEmail);
            let leg: Leg;
            if (action.ids.length > 0) {
                const id = action.ids[0];
                this._legService.find(id).then((entity) => {
                    leg = entity as Leg;
                    if (leg) {
                        ctx.dispatch(new GetEntity(this._runResource, leg.runId));
                    }
                });
            }
            ctx.dispatch(new GetEntityList(this._trafficSheetResource));
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    if (!draft) {
                        console.log('getstate() returned undefined ');
                    } else {
                        const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                        trafficSheetEntityView.statusSaving = false;
                        const runEntityView = draft[this._runResource.name].entityView;
                        runEntityView.statusSaving = false;
                    }
                })
            );
            return leg;
        } catch (err) {
            const runEntityAction: IEntityAction = {
                resource: this._runResource,
                currentPage: EntityView.VIEW
            };
            const trafficSheetEntityAction: IEntityAction = {
                resource: this._trafficSheetResource,
                currentPage: EntityView.LIST
            };
            this.resetUpdateState(ctx, runEntityAction);
            this.resetUpdateState(ctx, trafficSheetEntityAction);
            throw err;
        }
    }

    @Action(Communicate)
    async communicate(ctx: StateContext<EntitiesStateModel>, action: ILegActions): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                if (!draft) {
                    console.log('getstate() returned undefined ');
                } else {
                    const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                    trafficSheetEntityView.statusSaving = true;
                    const runEntityView = draft[this._runResource.name].entityView;
                    runEntityView.statusSaving = true;
                }
            })
        );
        try {
            await this._legService.communicateLegs(action.ids);
            let leg: Leg;
            if (action.ids.length > 0) {
                const id = action.ids[0];
                this._legService.find(id).then((entity) => {
                    leg = entity as Leg;
                    if (leg) {
                        ctx.dispatch(new GetEntity(this._runResource, leg.runId));
                    }
                });
            }
            ctx.dispatch(new GetEntityList(this._trafficSheetResource));
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    if (!draft) {
                        console.log('getstate() returned undefined ');
                    } else {
                        const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                        trafficSheetEntityView.statusSaving = false;
                        const runEntityView = draft[this._runResource.name].entityView;
                        runEntityView.statusSaving = false;
                    }
                })
            );
            return leg;
        } catch (err) {
            const runEntityAction: IEntityAction = {
                resource: this._runResource,
                currentPage: EntityView.VIEW
            };
            const trafficSheetEntityAction: IEntityAction = {
                resource: this._trafficSheetResource,
                currentPage: EntityView.LIST
            };
            this.resetUpdateState(ctx, runEntityAction);
            this.resetUpdateState(ctx, trafficSheetEntityAction);
            throw err;
        }
    }

    @Action(UnCommunicate)
    async unCommunicate(ctx: StateContext<EntitiesStateModel>, action: ILegActions): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                if (!draft) {
                    console.log('getstate() returned undefined ');
                } else {
                    const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                    trafficSheetEntityView.statusSaving = true;
                    const runEntityView = draft[this._runResource.name].entityView;
                    runEntityView.statusSaving = true;
                }
            })
        );
        try {
            await this._legService.unCommunicateLegs(action.ids);
            let leg: Leg;
            if (action.ids.length > 0) {
                const id = action.ids[0];
                this._legService.find(id).then((entity) => {
                    leg = entity as Leg;
                    if (leg) {
                        ctx.dispatch(new GetEntity(this._runResource, leg.runId));
                    }
                });
            }
            ctx.dispatch(new GetEntityList(this._trafficSheetResource));
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    if (!draft) {
                        console.log('getstate() returned undefined ');
                    } else {
                        const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                        trafficSheetEntityView.statusSaving = false;
                        const runEntityView = draft[this._runResource.name].entityView;
                        runEntityView.statusSaving = false;
                    }
                })
            );
            return leg;
        } catch (err) {
            const runEntityAction: IEntityAction = {
                resource: this._runResource,
                currentPage: EntityView.VIEW
            };
            const trafficSheetEntityAction: IEntityAction = {
                resource: this._trafficSheetResource,
                currentPage: EntityView.LIST
            };
            this.resetUpdateState(ctx, runEntityAction);
            this.resetUpdateState(ctx, trafficSheetEntityAction);
            throw err;
        }
    }

    @Action(SubContract)
    async subContract(ctx: StateContext<EntitiesStateModel>, action: any): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                if (!draft) {
                    console.log('getstate() returned undefined ');
                } else {
                    const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                    trafficSheetEntityView.statusSaving = true;
                    const runEntityView = draft[this._runResource.name].entityView;
                    runEntityView.statusSaving = true;
                }
            })
        );
        try {
            await this._legService.subContractLegs(action.resources);
            let leg: Leg;
            if (action.resources.subContractorLegs) {
                const id = action.resources.subContractorLegs[0].legId;
                this._legService.find(id).then((entity) => {
                    leg = entity as Leg;
                    if (leg) {
                        ctx.dispatch(new GetEntity(this._runResource, leg.runId));
                    }
                });
            }
            ctx.dispatch(new GetEntityList(this._trafficSheetResource));
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    if (!draft) {
                        console.log('getstate() returned undefined ');
                    } else {
                        const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                        trafficSheetEntityView.statusSaving = false;
                        const runEntityView = draft[this._runResource.name].entityView;
                        runEntityView.statusSaving = false;
                    }
                })
            );
            this._alertService.showSuccess(`runs.messages.subContractSuccessful.message`);
            return leg;
        } catch (err) {
            const runEntityAction: IEntityAction = {
                resource: this._runResource,
                currentPage: EntityView.VIEW
            };
            const trafficSheetEntityAction: IEntityAction = {
                resource: this._trafficSheetResource,
                currentPage: EntityView.LIST
            };
            this.resetUpdateState(ctx, runEntityAction);
            this.resetUpdateState(ctx, trafficSheetEntityAction);
            this._alertService.showError(`${action.resource.name}.messages.subContractNotSuccessful.message`);
            throw err;
        }
    }

    @Action(SetTravelNotes)
    async setTravelNotes(ctx: StateContext<EntitiesStateModel>, action: ITravelNotes): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                if (!draft) {
                    console.log('getstate() returned undefined ');
                } else {
                    const trafficSheetEntityView = draft[this._trafficSheetResource.name].entityView;
                    const driverEntityView = draft[this._driverResource.name].entityView;
                    driverEntityView.statusSaving = true;
                    trafficSheetEntityView.statusSaving = true;
                }
            })
        );
        try {
            await this._driverService.updateTravelNotes(action.driverId, action.travelNotes);
            this._driverService.find(action.driverId).then((entity) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        if (!draft) {
                            console.log('getstate() returned undefine ');
                        } else {
                            const entityView = draft[this._trafficSheetResource.name].entityView;
                            const driverEntityView = draft[this._driverResource.name].entityView;
                            driverEntityView.statusSaving = false;
                            entityView.statusSaving = false;
                        }
                    })
                );
                return entity;
            });
            ctx.dispatch(new GetEntityList(this._trafficSheetResource));
            ctx.dispatch(new ForceGetEntityList(this._driverResource));
        } catch (err) {
            const entityActionforDrivers: IEntityAction = {
                resource: this._driverResource,
                currentPage: EntityView.LIST
            };
            const entityAction: IEntityAction = {
                resource: this._trafficSheetResource,
                currentPage: EntityView.LIST
            };
            this.resetUpdateState(ctx, entityActionforDrivers);
            this.resetUpdateState(ctx, entityAction);
            throw err;
        }
    }

    @Action(CallInLeg)
    async CallInLeg(ctx: StateContext<EntitiesStateModel>, action: ICallInLeg): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                if (!draft) {
                    console.log('getstate() returned undefined ');
                } else {
                    const tsListView = draft[this._trafficSheetResource.name].listView;
                    tsListView.statusSaving = true;
                }
            })
        );

        try {
            this._legService.updateCallInLeg(action.callIn).then((entity) => {
                ctx.dispatch(new ForceGetEntityList(this._trafficSheetResource));
                ctx.dispatch(new ForceGetEntityList(this._lastCallInResource));
                ctx.dispatch(new GetEntity(this._legResource, entity.id));
                ctx.dispatch(new GetEntity(this._runResource, entity.runId));
                ctx.dispatch(new GetOutstandingCallIns());
                return entity;
            });
        } catch (err) {
            const entityActionforDrivers: IEntityAction = {
                resource: this._legResource,
                currentPage: EntityView.LIST
            };
            const legEntityAction: IEntityAction = {
                resource: this._trafficSheetResource,
                currentPage: EntityView.LIST
            };
            this.resetUpdateState(ctx, entityActionforDrivers);
            this.resetUpdateState(ctx, legEntityAction);
            throw err;
        }
    }

    @Action(CreateOrganisation)
    async createOrganisation(ctx: StateContext<EntitiesStateModel>, action: ICreateOrganisation): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            let entity = await action.resource.api.create(action.entity);

            if (action.logo) {
                const logo = action.logo as Blob;
                await this._organisationService.uploadLogo(entity.id, logo, action.width, action.height);
            }

            entity = await action.resource.api.find(entity.id);

            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityAdded.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(UpdateOrganisation)
    async updateOrganisation(ctx: StateContext<EntitiesStateModel>, action: IUpdateOrganisation): Promise<any> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const entityView = draft[action.resource.name]['entityView'];
                entityView.statusSaving = true;
            })
        );
        try {
            let entity = await action.resource.api.update(action.entity);
            if (action.logo) {
                const logo = action.logo as Blob;
                await this._organisationService.uploadLogo(entity.id, logo, action.width, action.height);
            }

            entity = await action.resource.api.find(entity.id);
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const entityView = draft[action.resource.name]['entityView'];
                    entityView.currentEntity = entity;
                    entityView.statusSaving = false;
                    entityView.statusDeleting = false;

                    const state = ctx.getState();
                    for (const listName of Object.keys(state[action.resource.name].partialEntity)) {
                        draft[action.resource.name].partialEntity[listName].loaded = false;
                    }
                })
            );
            this._alertService.showSuccess(`${action.resource.name}.messages.entityUpdated.message`, null, {discriminator: entity.discriminator});
            return entity;
        } catch (err) {
            this.resetUpdateState(ctx, action);
            throw err;
        }
    }

    @Action(UnselectAllLegTrafficSheets)
    async UnselectAllLegTrafficSheets(ctx: StateContext<EntitiesStateModel>, action: IEntityAction): Promise<any> {
        const prevState = ctx.getState();
        const listView: any = prevState[action.resource.name]['listView'];
        const toUnselectEntityIds: any[] = listView.selectedIds;

        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView'].selectedIds = draft[action.resource.name]['listView'].selectedIds.filter(
                    (id) => !toUnselectEntityIds.includes(parseInt(id))
                );
            })
        );
    }

    @Action(SelectAllLegTrafficSheets)
    async SelectAllLegTrafficSheets(ctx: StateContext<EntitiesStateModel>, action: IEntityAction): Promise<any> {
        const prevState = ctx.getState();
        const listView: any = prevState[action.resource.name]['listView'];
        const toUnselectEntityIds: any[] = listView.selectedIds;

        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[action.resource.name]['listView'].selectedIds = draft[action.resource.name]['listView'].entityList.map((entity) => entity.id);
            })
        );
    }

    @Action(GetOutstandingCallIns)
    async getOutstandingCallIns(ctx: StateContext<EntitiesStateModel>, action: IGetOutstandingCallIns): Promise<any[]> {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft[Resource.OUTSTANDING_CALL_INS].loading = true;
            })
        );

        let filters = action.filters;
        const prevFilters = ctx.getState() ? ctx.getState()[Resource.OUTSTANDING_CALL_INS].filters : null;

        if (!filters) {
            if (!prevFilters) {
                filters = {};

                const res = await this._filterSettingService.getFilterSettings(Resource.OUTSTANDING_CALL_INS);

                if (res.body && res.body.length) {
                    res.body.forEach((filter) => (filters[filter.filter] = filter.value));
                }
            } else {
                filters = prevFilters;
            }
        }

        const req = {};
        Object.entries(filters).forEach((entry) => {
            if (entry[1]) {
                req[entry[0]] = entry[1];
            }
        });

        return this._legService.getOutstandingCallIns(req).then((result) => {
            ctx.setState(
                produce(ctx.getState(), (draft) => {
                    const callInsState = draft[Resource.OUTSTANDING_CALL_INS];
                    callInsState.filters = filters;
                    callInsState.data = result;
                    callInsState.loading = false;
                })
            );
            return result;
        });
    }

    // Private methods

    private async _fetchEntityList(
        ctx: StateContext<EntitiesStateModel>,
        resource: AbstractEntityResource<any>,
        viewParams: ListViewParams,
        api: (request: any) => Promise<HttpResponse<any[]>>,
        primaryFilter: string,
        resourceName: string
    ): Promise<any[]> {
        const prevState = ctx.getState();
        ctx.setState({
            ...prevState,
            [resourceName]: {
                ...prevState[resourceName],
                listView: {
                    ...prevState[resourceName].listView,
                    statusLoading: true
                }
            }
        });

        const res = await api(this._apiParams(viewParams, primaryFilter));
        const entityList = res.body;
        const totalEntityCount = parseInt(res.headers.get('X-Total-Count'), 10);
        if (entityList.length === 0 && totalEntityCount > 0) {
            const lastPageIndex = this._computeLastPageIndex(viewParams.pageSize, totalEntityCount);
            if (lastPageIndex < viewParams.pageIndex) {
                return this._fetchEntityList(ctx, resource, {...viewParams, pageIndex: lastPageIndex}, api, primaryFilter, resourceName);
            }
        }
        this._updateFetchEntityListState(ctx, resource, entityList, totalEntityCount, viewParams, primaryFilter, resourceName);
        return entityList;
    }

    private _computeLastPageIndex(pageSize: number, totalItems: number): number {
        return Math.ceil(totalItems / pageSize) - 1;
    }

    private _updateFetchEntityListState(
        ctx: StateContext<EntitiesStateModel>,
        resource: AbstractEntityResource<any>,
        entityList: any[],
        totalItems: number,
        viewParams: ListViewParams,
        primaryFilter: string,
        resourceName: string
    ): void {
        const prevState = ctx.getState();
        const oldSelectedIds = prevState[resourceName]['listView'].selectedIds;
        ctx.setState({
            ...prevState,
            [resourceName]: {
                ...prevState[resourceName],
                listView: {
                    initialized: true,
                    totalItems,
                    viewParams,
                    entityList,
                    statusLoading: false,
                    selectedIds: oldSelectedIds,
                    primaryFilter: primaryFilter
                }
            }
        });
        // console.log(
        //    `[EntityState] Entity list state updated for ${resource.name}`
        // );
    }

    private _apiParams(viewParams: ListViewParams, primaryFilter: string): any {
        const returnValue = {
            page: viewParams.pageIndex,
            size: viewParams.pageSize,
            sort: viewParams.sortColumn + ',' + viewParams.sortDirection,
            query: viewParams.filter
        };

        const filters = viewParams.filterParams;
        if (filters) {
            for (const filter of filters) {
                if (filter.currentValue) {
                    returnValue[filter.columnName] = filter.currentValue.toString();
                }
            }
        }

        if (primaryFilter) {
            returnValue['primaryFilter'] = primaryFilter;
        }

        return returnValue;
    }
}
