import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface ICountry extends IBaseEntity {
    id?: number;
    code?: string;
    name?: string;
}

export class Country extends BaseEntity implements ICountry {
    public id?: number;
    public code?: string;
    public name?: string;

    constructor(country?) {
        super();
        if (country) {
            this.id = country.id;
            this.code = country.code;
            this.name = country.name;
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): Country {
        return new Country(this);
    }
}
